<template>
	<div class="content_wrap">
		<div class="content_area_wrap">
			<router-view v-slot="{ Component, route }">
				<transition name="fade" mode="out-in" appear>
					<component :is="Component" :key="route.path" />
				</transition>
			</router-view>
		</div>
	</div>
</template>

<script setup></script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: 0.2s;
}

.fade-enter-active {
	transition-delay: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
